import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '~/components/ui/form';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate, useSearchParams} from '@remix-run/react';
import type {To} from '@remix-run/react';
import {Input} from './ui/input';
import {useEffect, useMemo, useRef} from 'react';
import {useEventListener} from 'usehooks-ts';

type FormValues = {
  command: string;
};

type PromptProps = {
  commands?: Record<string, (args: string[]) => void>;
};

const getInputCommand = (command: string) => {
  return command.split(' ');
};

export const getPathFromCommand = (data: {command: string}): To => {
  const [inputCommand, ...args] = getInputCommand(data.command);
  const destinations: Record<string, string> = {
    home: '/',
  };

  if (inputCommand === 'back') {
    return -1;
  }

  return (
    (destinations[inputCommand] || `/${inputCommand}`) +
    (args.length > 0 ? '/' + args.join('/') : '')
  );
};

export const Prompt = ({commands: commandsProp = {}}: PromptProps) => {
  const [URLSearchParams] = useSearchParams();
  const command = URLSearchParams.get('command');
  const methods = useForm<FormValues>({
    defaultValues: {
      command: '',
    },
  });

  const navigate = useNavigate();

  const commands = useMemo<
    Record<string, () => string | void | ReturnType<typeof navigate>>
  >(
    () => ({
      login: () => navigate('/login'),
      logout: () => navigate('/kinde-auth/logout'),
      register: () =>
        navigate({
          pathname: '/kinde-auth/register',
          search: '?org_code=org_ad433b348a6e',
        }),
      ...commandsProp,
    }),
    [commandsProp, navigate]
  );

  const onSubmit: SubmitHandler<FormValues> = data => {
    const [inputCommand, ...args] = getInputCommand(data.command);
    const result = getPathFromCommand(data);

    if (commands[inputCommand]) {
      const error = commands[inputCommand](args);
      if (error) {
        methods.setError('command', {
          type: 'manual',
          message: error,
        });
        return;
      }
    } else {
      navigate(result);
    }

    methods.reset();
  };

  const inputRef = useRef<HTMLInputElement | null>(null);
  const isFocused = useRef<boolean>(false);

  // Update default value when URLSearchParams changes
  useEffect(() => {
    if (command) {
      methods.setValue('command', `${command} `);
      inputRef.current?.focus();
    }
  }, [command, methods]);

  useEventListener('keydown', e => {
    const focusGuards = document.querySelectorAll('[data-radix-focus-guard]');
    if (focusGuards[0] || isFocused.current) return;
    // ignore when pressing a combination of keys
    if (e.ctrlKey || e.altKey || e.metaKey) return;
    // focus field when user presses an alphanumeric key or space
    if (/^[a-zA-Z0-9 ]$/.test(e.key)) {
      inputRef.current?.focus();
    }
  });

  return (
    <Form {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="group sticky bottom-2 mt-auto w-full rounded border-4 border-accent bg-background px-2 lg:max-w-screen-xl "
      >
        <FormField
          name="command"
          render={({field}) => (
            <FormItem className="flex items-center gap-1 space-y-0">
              <div className="animate-bounce-x font-title text-xl font-black text-accent">
                {'> '}
              </div>
              <FormControl>
                <Input
                  {...field}
                  ref={el => {
                    field.ref(el);
                    inputRef.current = el;
                  }}
                  onFocus={() => (isFocused.current = true)}
                  onBlur={() => {
                    field.onBlur();
                    isFocused.current = false;
                  }}
                  placeholder='e.g. Type "games" to list the games'
                  className="h-12 border-0 bg-transparent py-0 font-title text-2xl text-foreground caret-accent placeholder:text-accent focus-visible:ring-0 focus-visible:ring-offset-0"
                  autoCapitalize="off"
                  autoCorrect="off"
                />
              </FormControl>
              <FormMessage className="absolute bottom-full left-0 w-full p-2 shadow-destructive/50 custom-text-shadow-sm" />
            </FormItem>
          )}
        />
        <button className="sr-only" type="submit">
          Submit
        </button>
      </form>
    </Form>
  );
};
